<template>
	<div>
		<ClientOnly>
			<div
				v-if="showAccountSelector"
				class="h-screen"
			>
				<div class="h-full p-8 bg-mx-gray-100 dark:bg-mx-green-900 dark:text-white panel">
					<AccountSelector />
				</div>
			</div>
		</ClientOnly>
		<div v-show="!showAccountSelector">
			<Header
				id="header"
				class="z-20"
			/>

			<NavSideBar
				id="nav"
				v-model="sideBarCollapsed"
				class="fixed top-0 hidden h-full md:block"
			/>

			<div
				:class="[
					'relative z-10 h-auto min-h-screen pt-[calc(6vh)] pb-0 overflow-x-hidden transition-all lg:pt-0 bg-mx-gray-100 dark:bg-mx-green-900',
					sideBarCollapsed && !isAccountPortal ? 'md:ml-[70px]' : 'md:ml-60'
				]"
			>
				<div
					v-if="showAlertBanner"
					class="pt-4"
				>
					<LazyAlertBanner
						:alert="alert"
					/>
				</div>

				<div
					:class="[
						showAlertBanner ? '-mt-4': 'mt-0'
					]"
				>
					<slot name="title" />
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useShowAlert } from '@/composables/useState'
import type { AlertProps } from '@/types/alert'
import { isAlertResponseValid } from '@/types/alert'
import MyWorker from '@/assets/workers/alertWorker?worker'
import { isAccountPortal } from '#imports'
import { COMPANY_NAME } from '@/constants'

import { useAuth } from '@/composables/useAuth'

const showAlert = useShowAlert()

const { selectedAccount, accounts } = useAuth()

const worker = ref<Worker | null>(null)

const alert = ref<AlertProps>({ title: '', message: '', active: false })

const sideBarCollapsed = ref(false)

const showAccountSelector = computed(() => {
	return !!(accounts.value?.length && !selectedAccount.value && isAccountPortal.value)
})

const checkForAlerts = () => {
	worker.value = new MyWorker()
	worker.value.onmessage = (res) => {
		if (!res.data?.length) {
			alert.value.title = ''
			alert.value.message = ''
			alert.value.active = false
			showAlert.value = false
			return
		}
		if (res?.data?.isError) {
			reportError(res?.data?.error)
			return
		}
		if (isAlertResponseValid(res?.data?.[0])) {
			reportError('Invalid data returned from alerts endpoint')
			return
		}
		const { title, message, active } = res.data[0]
		alert.value.title = title || ''
		alert.value.message = message || ''
		showAlert.value = active ?? false
	}
}

const showAlertBanner = computed(() => {
	return showAlert.value && isAccountPortal.value
})

onMounted(() => {
	checkForAlerts()
	document.addEventListener('visibilitychange', () => {
		if (document.visibilityState === 'visible' && !worker.value) {
			checkForAlerts()
		} else {
			worker.value?.terminate()
			worker.value = null
		}
	})
})

useHead({
	title: COMPANY_NAME
})
</script>
