<template>
	<div class="relative flex flex-col items-center w-3/4 mx-auto my-8">
		<BrandingLogo class="h-12 my-10" />
		<div class="w-full">
			<h4 class="text-lg font-semibold">
				{{ t('account-selector.title') }}
			</h4>
			<span class="text-sm text-mx-gray-500 dark:text-mx-gray-400">
				{{ t('account-selector.subtitle') }}
			</span>
		</div>
		<div
			v-for="option in accountOptions"
			:key="option.id"
			:class="[
				selectedAccountId?.toString() === option.id?.toString() ? 'border border-mx-orange shadow-md dark:bg-mx-green-600' : 'bg-mx-gray-50 dark:bg-mx-green-800 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600',
				'flex items-center my-2 w-full p-4 rounded-xl group hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 cursor-pointer'
			]"
		>
			<FormRadio
				v-model="selectedAccountId"
				:option="option"
			/>
		</div>
		<div class="flex items-center w-full mt-4 space-x-6">
			<FormButton
				:button-text="t('account-selector.continue-to-address')"
				button-classes="uppercase"
				class="my-4"
				@click="setSelectedAccount(parseInt(selectedAccountId))"
			/>
			<button
				class="flex items-center space-x-2 text-mx-gray-500 dark:text-mx-gray-400"
				@click="handleLogout"
			>
				<IconLogout class="h-6" />
				<span class="mt-1 font-semibold">
					{{ t('account-selector.logout') }}
				</span>
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useAuth } from '@/composables/useAuth'
import IconLogout from '@/components/icon/Logout.vue'
import { useLogout } from '@/composables/useLogout'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { handleLogout } = useLogout()

const { accounts, setSelectedAccount } = useAuth()

const accountOptions = computed(() => {
	return accounts?.value.map((account) => {
		return {
			id: account.accountId,
			value: account.accountId,
			label: `#${account.accountId} ${account.address.streetAddress}, ${account.address.city}, ${account.address.stateIso} ${account.address.zip}`
		}
	})
})

const selectedAccountId = ref('')

</script>
